import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Grooming from 'src/components/OurProducts/Grooming'
import SEO from 'src/components/shared/SEO'
import Category from 'src/components/Products/category'
import PawCareBg from '../../assets/img/categories/pawcare.png'

const SEO_CONFIG = {
  title: 'Grooming | Finn',
  description:
    'Shop all the products that Finn has to offer. We formulate pet supplements that combine trusted research and modern wellness. Get trusted nutrition with Finn.',
  keywords: ['Grooming', 'Finn', 'Pet Grooming', 'Pet Care', 'Pet Products', 'Pet Supplements']
}

const ProductsPage: React.FC<PageProps> = ({ location }) => {
  const { title, description, keywords } = SEO_CONFIG

  return (
    <>
      <SEO title={title} description={description} keywords={keywords} location={location} />

      <Layout location={location}>
        <Category title="Grooming" image={PawCareBg}>
          <Grooming />
        </Category>
      </Layout>
    </>
  )
}

export default ProductsPage
